import React, { useEffect, useState } from "react";
import { S3_ENDPOINT } from "../../utils";

const StudioSlideshow = () => {
  const [active, setActive] = useState(0);

  const slides = [
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaStudio1.jpeg`,
    },
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaStudio6.jpeg`,
    },
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaStudio5.jpeg`,
    },
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaStudio2.jpeg`,
    },
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaStudio3.jpeg`,
    },
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaStudio4.jpeg`,
    },
  ];

  useEffect(() => {
    let interval = setInterval(handleNext, 3000);
    return () => {
      window.clearInterval(interval);
    };
  }, []);

  const handleNext = () => {
    if (active === slides.length - 1) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
  };

  const renderSlides = () => {
    if (Array.isArray(slides)) {
      return slides.map((slide, index) => (
        <div
          key={index}
          className={`carousel-item ${active === index ? "active" : ""}`}
        >
          <img
            className="d-block slideshow-img w-100 mw-100"
            src={slide.src}
            alt="First slide"
          />
          <div className="slideshow-text p-5">
            <img
              src={`${S3_ENDPOINT}/StudioLogo.png`}
              className="mb-4 slideshow-logo"
            />
            <div className="text-lg" style={{ maxWidth: 450 }}>
              Feel like a <i className="text-primary">diosa</i> while working
              out and having fun in our follow along dance cardio sessions.
            </div>
            <h1 className="display-3">Join The Tribe</h1>
            <div className="row">
              <div className="col-6">
                <button className="btn btn-primary mb-3 w-100">
                  START NOW
                </button>
              </div>
              <div className="col-6">
                <a href="#trailer" className="btn btn-light border w-100">
                  Watch Trailer
                </a>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  };

  const renderIndicators = () => {
    if (Array.isArray(slides)) {
      return slides.map((slide, index) => (
        <li
          key={index}
          data-target="#carouselExampleIndicators"
          data-slide-to={index}
          onClick={() => setActive(index)}
          className={active === index ? "active" : ""}
        ></li>
      ));
    }
  };

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-ride="carousel"
    >
      <ol className="carousel-indicators">{renderIndicators()}</ol>
      <div className="carousel-inner">{renderSlides()}</div>
      <div className="carousel-overlay"></div>
    </div>
  );
};

export default StudioSlideshow;
