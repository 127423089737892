import React, { useContext, useEffect } from "react";
import { AppConfigContext } from "./context/AppConfigContext";
import SuccessAlert from "./components/common/SuccessAlert";
import ErrorAlert from "./components/common/ErrorAlert";
import { AuthContext } from "./context/AuthContext";
import Modal from "./components/global/Modal";
import Checkout from "./views/Checkout";
import ThankYou from "./views/ThankYou";
import { Router } from "@reach/router";
import Loading from "./views/Loading";
import Home from "./views/Home";
import AtHome from "./AtHome";
import Studio from "./Studio";
import BichotaSeason from "./views/BichotaSeason";
import Terms from "./views/pages/Terms";

const Main = () => {
  const { userLoggedIn } = useContext(AuthContext);
  const { getAppConfig } = useContext(AppConfigContext);

  useEffect(() => {
    userLoggedIn();
    getAppConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Router>
        <Home path="/*" default />
        <Checkout path="/checkout/:class_package_id/*" />
        <ThankYou path="/thankyou/:payment_id/*" />
        <BichotaSeason path="/bichota" />
        <Loading path="/mylatina/*" />
        <AtHome path="/athome/*" />
        <Studio path="/studio/*" />
        <Terms path="/terminos-y-condiciones" />
      </Router>
      <Modal />
      <ErrorAlert />
      <SuccessAlert />
    </>
  );
};

export default Main;
