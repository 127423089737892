import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";

const ExpiredAtHome = ({ max_expiration_date }) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    showModal();
  }, []);

  const showModal = () => {
    const button = document.getElementById("fest-button");
    if (button && button !== null) {
      button.click();
    } else {
      const newButton = document.createElement("button");
      newButton.attributes.href = "#modal-fest";
      newButton.id = "fest-button";
      newButton.setAttribute("data-toggle", "modal");
      newButton.setAttribute("data-target", "#modal-fest");
      newButton.setAttribute("data-bs-toggle", "modal");
      newButton.setAttribute("data-bs-target", "#modal-fest");
      newButton.style.visibility = "hidden";
      document.body.appendChild(newButton);
      newButton.click();
    }
  };

  const hideModal = () => {
    window.localStorage.setItem("athome_expiration_date", max_expiration_date);
    const button = document.getElementById("fest-button");
    if (button && button !== null) {
      button.click();
    }
  };

  const handleClick = () => {
    hideModal();
    window.localStorage.setItem("athome_expiration_date", max_expiration_date);
    navigate("/athome/mylatina/shop");
  };

  return (
    <div
      role="dialog"
      tabIndex="-1"
      id="modal-fest"
      className="modal fade"
      aria-labelledby="modal"
      aria-hidden={open ? "false" : "true"}
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <img
            src={`${S3_ENDPOINT}/banner-athome-desktop.jpg`}
            className="mw-100 w-100 hide-mobile m-auto"
          />
          <img
            src={`${S3_ENDPOINT}/banner-athome-movil.jpg`}
            className="mw-100 w-100 show-mobile m-auto"
          />
          <div className="row mx-0 my-3">
            <div className="col-6">
              <button className="btn text-muted w-100" onClick={hideModal}>
                No, gracias
              </button>
            </div>
            <div className="col-6">
              <button onClick={handleClick} className="btn btn-primary w-100">
                Renovar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredAtHome;
