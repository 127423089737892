export const getInstructorName = (instructor) => {
  return `${
    instructor !== null
      ? instructor.nick_name !== null
        ? instructor.nick_name
        : instructor.name
      : ""
  }`;
};

export const getClassInstructorsNames = (class_instructors) => {
  if (Array.isArray(class_instructors)) {
    return class_instructors
      .map(({ instructor }) => getInstructorName(instructor))
      .join(", ");
  }
};
