import React from "react";
import Footer from "../components/global/Footer";
import AppLink from "../components/home/AppLink";
import { S3_ENDPOINT } from "../utils";

const Landing = () => {
  return (
    <div id="main-landing">
      <div className="row home-row">
        <AppLink
          src={`${S3_ENDPOINT}/LatinaHome2.jpeg`}
          href="/athome"
          logo={`${S3_ENDPOINT}/LatinaAtHome.png`}
        />
        <AppLink
          src={`${S3_ENDPOINT}/LatinaStudio1.jpeg`}
          href="/studio"
          logo={`${S3_ENDPOINT}/StudioLogo.png`}
        />
      </div>
      <div className="row home-row">
        <AppLink
          src={`${S3_ENDPOINT}/merch-04.png`}
          logo={`${S3_ENDPOINT}/merch-05.png`}
          href="https://shop.latinatribe.mx"
        />
        <AppLink
          src={`${S3_ENDPOINT}/LatinaInstructor.jpeg`}
          href="https://instructor.latinatribe.mx"
          logo={`${S3_ENDPOINT}/instructor-31.png`}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
