import React, { useState } from "react";
import SingleCoach from "../coaches/SingleCoach";
import MapaLugares from "./MapaLugares";
import moment from "moment";
import {
  getClassReservation,
  getMapRows,
  getTakenSpots,
  isReserved,
} from "../../utils/single_class";
import { CANCEL_TRESHOLD, CANCEL_UNIT } from "../../constants";
import { getLocalMoment } from "../../utils";

const SingleClass = ({
  clase,
  hideModal,
  reservations,
  postReservacion,
  cancelReservacion,
}) => {
  const [place, setPlace] = useState(null);

  const handleReservation = () => {
    postReservacion({ ...clase, spot: place });
  };

  const renderCoaches = () => {
    if (Array.isArray(clase.class_instructors)) {
      return clase.class_instructors.map((class_instructor) => {
        return (
          <SingleCoach
            key={class_instructor.instructor.instructor_id}
            coach={class_instructor.instructor}
          />
        );
      });
    }
  };

  const renderButtons = () => {
    if (isReserved(clase.single_class_id, reservations)) {
      let localClassDate = getLocalMoment(clase.class_date);
      let cancelDiff = localClassDate.diff(moment(), CANCEL_UNIT);
      if (cancelDiff >= CANCEL_TRESHOLD) {
        return (
          <div className="row">
            <div className="col col-md-6">
              <button
                className="btn w-100 btn-danger"
                onClick={cancelReservacion}
              >
                Cancelar
              </button>
            </div>
            <div className="col col-md-6">
              <button
                className="btn w-100 btn-link text-muted"
                onClick={hideModal}
              >
                Cerrar
              </button>
            </div>
          </div>
        );
      }
      return (
        <div className="container-fluid">
          <p>
            Ya no puedes cancelar esta reservación porque faltan menos de{" "}
            {CANCEL_TRESHOLD} horas para la clase.
          </p>
          <button className="btn w-100 btn-link text-muted" onClick={hideModal}>
            Cerrar Mapa
          </button>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col col-md-6">
          <button className="btn w-100 btn-primary" onClick={handleReservation}>
            Reservar
          </button>
        </div>
        <div className="col col-md-6 text-right pe-0">
          <button className="btn w-100 btn-link text-muted" onClick={hideModal}>
            Cancelar
          </button>
        </div>
      </div>
    );
  };

  const renderMap = () => {
    if (clase.class_type !== null && clase.class_type.spot_map !== null) {
      const class_reservation = getClassReservation(
        clase.single_class_id,
        reservations
      );
      const selected =
        class_reservation && class_reservation !== null
          ? class_reservation.spot
          : null;
      return (
        <MapaLugares
          place={place}
          selected={selected}
          setPlace={setPlace}
          rows={getMapRows(clase)}
          taken_spots={getTakenSpots(clase.class_reservations)}
        />
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <h3>{clase.description}</h3>
      <p className="large">
        <i className="far fa-calendar me-2"></i>{" "}
        {moment(clase.class_date).utc().format("DD MMM YYYY")}{" "}
        <i className="far fa-clock mx-2"></i>{" "}
        {moment(clase.class_date).utc().format("HH:mm")} hrs.
      </p>
      <h5 className="mt-4">Coaches</h5>
      {renderCoaches()}
      <h5>Ubicación</h5>
      <p className="large">{clase.location.name}</p>
      <p>{clase.location.address}</p>
      {renderMap()}
      {renderButtons()}
    </div>
  );
};

export default SingleClass;
