import React from "react";

const AppLink = ({ src, href, title, logo }) => {
  return (
    <div
      className="col-12 col-md-6 px-0 app-link home-img"
      onClick={() => window.open(href, "_self")}
      style={{ backgroundImage: `url(${src})` }}
    >
      {title && <h1 className="app-link-title">{title}</h1>}
      {logo && <img src={logo} className="app-link-logo" />}
      <div className="app-link-overlay"></div>
    </div>
  );
};

export default AppLink;
