import { navigate } from "@reach/router";
import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const RestrictedCredits = ({ class_type_name }) => {
  const { clearModal } = useContext(ModalContext);
  return (
    <div className="container-fluid px-0">
      <p>
        Lo sentimos. No tienes créditos disponibles para{" "}
        <b>reservar clases de {class_type_name}</b>. Puedes comprar más en Shop.
      </p>
      <p className="bold">
        Después de comprar, debes regresar a reservar tu clase.
      </p>
      <div className="row">
        <div className="col-6">
          <button
            onClick={() => {
              navigate("/studio/mylatina/creditos");
              clearModal();
            }}
            className="btn w-100 text-white btn-outline-light"
          >
            Mis Créditos
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() => {
              navigate("/studio/mylatina/shop");
              clearModal();
            }}
            className="btn w-100 btn-primary"
          >
            Ir a Shop
          </button>
        </div>
      </div>
    </div>
  );
};

export default RestrictedCredits;
