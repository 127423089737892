import React, { useState, useEffect, useContext } from "react";
import utils from "../schedule/utils";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import ScheduleMobile from "./ScheduleMobile";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import ScheduleLocationPicker from "./ScheduleLocationPicker";
import { ReservationsContext } from "../../context/ReservationsContext";
import { ClassInstructorContext } from "../../context/ClassInstructorContext";

const Schedule = () => {
  const [view, setView] = useState("week");
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [currentClasses, setCurrentClasses] = useState(null);

  const {
    days,
    update,
    getSchedule,
    start_date,
    end_date,
    setStartDate,
    setEndDate,
  } = useContext(ClassInstructorContext);

  const { reservations, enableReservations } = useContext(ReservationsContext);

  useEffect(() => {
    enableReservations();
  }, [reservations]);

  useEffect(() => {
    const date = utils.getStartDate(view);
    setStartDate(date);
  }, []);

  useEffect(() => {
    setEndDate(utils.getEndDate(view, start_date));
  }, [start_date]);

  useEffect(() => {
    if (Array.isArray(days)) {
      if (view === "week") {
        setCurrentClasses(days.slice(0, 7));
      } else {
        setCurrentClasses(days);
      }
    }
  }, [days]);

  useEffect(() => {
    fetchSchedule();
  }, [start_date, end_date, update]);

  const fetchSchedule = () => {
    if (utils.validDates(start_date, end_date)) {
      getSchedule(start_date, end_date);
    }
  };

  const handleNextWeek = () => {
    setStartDate(utils.getStartNext("week", start_date));
  };

  const handlePrevWeek = () => {
    setStartDate(utils.getStartPrev("week", start_date));
  };

  const handleNextMonth = () => {
    setStartDate(utils.getStartNext("month", start_date));
  };

  const handlePrevMonth = () => {
    setStartDate(utils.getStartPrev("month", start_date));
  };

  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-12 col-xl-2 my-2">
          <ScheduleMonthSelect
            start_date={start_date}
            handleNext={handleNextMonth}
            handlePrev={handlePrevMonth}
          />
          <ScheduleLocationPicker
            selectedCity={city}
            modifier={setLocation}
            cityModifier={setCity}
            selectedLocation={location}
          />
        </div>
        <div className="col-12 col-xl-10 my-2">
          <ScheduleWeekSelect
            start_date={start_date}
            handleNextWeek={handleNextWeek}
            handlePrevWeek={handlePrevWeek}
          />
          <ScheduleMobile
            city={city}
            location={location}
            weekClasses={currentClasses}
          />
        </div>
      </div>
    </div>
  );
};

export default Schedule;
