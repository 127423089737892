import React from "react";
import ClassPackageTypesTable from "../components/class_package_types/ClassPackageTypesTable";

const MisCreditos = () => {
  return (
    <div>
      <ClassPackageTypesTable />
    </div>
  );
};
export default MisCreditos;
