import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import { Link } from "@reach/router";

const ClassPackageTypesTable = () => {
  const { user, getUsuario } = useContext(AuthContext);
  const { class_types, getClassTypes } = useContext(ClassTypeContext);

  useEffect(() => {
    getClassTypes();
    getUsuario();
  }, []);

  const getAvailableClasses = (class_type) => {
    let available_classes = user.available_classes.filter(
      ({ class_type_id }) =>
        String(class_type_id) === String(class_type.class_type_id)
    );
    return available_classes.length;
  };

  const renderAvailableClasses = () => {
    if (Array.isArray(class_types)) {
      let restricted = class_types.filter(
        (class_type) => class_type.restricted
      );
      return [
        <tr key="General">
          <td>Power Moves</td>
          <td>{getAvailableClasses({ class_type_id: null })}</td>
        </tr>,
      ].concat(
        restricted.map((class_type) => (
          <tr key={class_type.class_type_id}>
            <td>{class_type.name}</td>
            <td>{getAvailableClasses(class_type)}</td>
          </tr>
        ))
      );
    }
  };
  return (
    <div>
      <div className="row mb-3">
        <div className="col-6">
          <h2>Mis Créditos</h2>
        </div>
        <div className="col-6 text-end">
          <Link to="/studio/mylatina/shop" className="btn btn-primary">
            + Créditos
          </Link>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table text-white">
          <thead>
            <tr>
              <td>Tipo de Clase</td>
              <td>Créditos</td>
            </tr>
          </thead>
          <tbody>{renderAvailableClasses()}</tbody>
        </table>
      </div>
    </div>
  );
};
export default ClassPackageTypesTable;
