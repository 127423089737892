import React, { useState, useContext, useEffect } from "react";
import { LocationsContext } from "../../context/LocationsContext";

const LocationsHome = () => {
  const [selectedUrl, setSelectedUrl] = useState("");

  const { locations, getLocations } = useContext(LocationsContext);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (Array.isArray(locations)) {
      if (locations[0]) {
        setSelectedUrl(locations[0].maps_url);
      }
    }
  }, [locations]);

  const renderLocations = () => {
    if (locations && locations !== null) {
      return locations.slice(0, 4).map((location) => (
        <div
          key={location.location_id}
          className="card p-3 my-3"
          onClick={() => setSelectedUrl(location.maps_url)}
        >
          <h4>
            <i className="fa fa-map-marker-alt me-2 text-primary"></i>
            {location.name}
          </h4>
          <p>{location.address}</p>
        </div>
      ));
    }
  };

  return (
    <div id="ubicaciones" className="container-fluid bg-dark py-5">
      <div className="container">
        <h2 className="text-white text-center">Ubicaciones</h2>
        <div className="row align-items-center my-4">
          <div className="col-12 col-md-6">{renderLocations()}</div>
          <div className="col-12 col-md-6 hide-mobile">
            <div className="card p-3">
              <iframe
                src={selectedUrl}
                width="100%"
                height="500"
                allowFullScreen
                title="Location"
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationsHome;
