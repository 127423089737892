import moment from "moment";
import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { LocationsContext } from "../../context/LocationsContext";
import LocationCard from "../../components/locations/LocationCard";
import { ReservationsContext } from "../../context/ReservationsContext";
import ExpiredAvailableClasses from "../../components/global/ExpiredAvailableClasses";
import Schedule from "../../components/schedule/Schedule";

const Presenciales = () => {
  const { user, getUsuario } = useContext(AuthContext);
  const { getMyReservations } = useContext(ReservationsContext);
  const { locations, getLocations } = useContext(LocationsContext);

  useEffect(() => {
    getUsuario();
    getLocations();
    getMyReservations();
  }, []);

  const renderExpiredCredits = () => {
    let max_expiration_date = getAvailableClassesExpirationDate();
    if (max_expiration_date && max_expiration_date !== null) {
      const tomorrow = moment()
        .startOf("day")
        .add(1, "day")
        .format("YYYY-MM-DD");
      max_expiration_date = moment(max_expiration_date)
        .utc()
        .startOf("day")
        .format("YYYY-MM-DD");
      const has_shown_modal = window.localStorage.getItem(
        "studio_expiration_date"
      );
      if (
        tomorrow === max_expiration_date &&
        max_expiration_date !== has_shown_modal
      ) {
        return (
          <ExpiredAvailableClasses max_expiration_date={max_expiration_date} />
        );
      }
    }
  };

  const renderUbicaciones = () => {
    if (locations && locations !== null) {
      return locations.map((location) => (
        <LocationCard key={location.location_id} location={location} />
      ));
    }
  };

  const getAvailableClassesExpirationDate = () => {
    let max_expiration_date = null;
    user.available_classes.forEach(({ expiration_date }) => {
      if (max_expiration_date === null) {
        max_expiration_date = expiration_date;
      } else if (moment(expiration_date).isAfter(max_expiration_date)) {
        max_expiration_date = expiration_date;
      }
    });
    if (max_expiration_date !== null) return max_expiration_date;
  };

  return (
    <div className="container-fluid ps-0">
      <h1>Calendario</h1>
      <div className="row align-items-center">
        <div className="col-6 col-sm-12 mobile-left">
          <Link className="h3 text-white" to={"/studio/mylatina/creditos"}>
            Créditos: {user.available_classes.length}
          </Link>
        </div>
        <div className="col-6 col-sm-12 text-end pe-0">
          <Link
            to="/studio/mylatina/reservaciones"
            className="btn btn-sm btn-outline-light"
          >
            Reservaciones
          </Link>
        </div>
      </div>
      <Schedule />
      <h2 className="border-bottom pb-3 mt-5">Ubicaciones</h2>
      <div className="row">{renderUbicaciones()}</div>
      {renderExpiredCredits()}
    </div>
  );
};

export default Presenciales;
