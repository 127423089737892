import React, { useEffect, useContext } from "react";
import StudioSlideshow from "../../components/global/StudioSlideshow";
import LocationsHome from "../../components/locations/LocationsHome";
import HomeCoachCard from "../../components/coaches/HomeCoachCard";
import PaqueteCard from "../../components/paquetes/PaquetesCard";
import StudioNavbar from "../../components/global/StudioNavbar";
import { ClassClassPackageContext } from "../../context/ClassPackageContext";
import { CoachesContext } from "../../context/CoachesContext";
import Footer from "../../components/global/Footer";
import { S3_ENDPOINT } from "../../utils";
import { navigate } from "@reach/router";
import Schedule from "../../components/schedule/Schedule";

const StudioLanding = () => {
  const { paquetes, getPaquetesStudio } = useContext(ClassClassPackageContext);
  const { coaches, getCoaches } = useContext(CoachesContext);

  const handleScroll = (e) => {
    let navbar = document.getElementById("navbar");
    if (window.scrollY > 100 && navbar !== null) {
      navbar.style.backgroundColor = "#000";
    } else if (navbar !== null) {
      navbar.style.backgroundColor = "transparent";
    }
  };

  useEffect(() => {
    getCoaches();
    getPaquetesStudio();
    document.onscroll = handleScroll;
  }, []);

  const toCheckout = (class_package_id) => {
    navigate(`/checkout/${class_package_id}`);
  };

  const renderPresenciales = () => {
    if (paquetes && paquetes !== null) {
      let paquetesRender = [...paquetes].filter(paquete => !paquete.is_special_event);
      return paquetesRender.map((presencial) => (
        <PaqueteCard
          size="sm"
          key={presencial.class_package_id}
          paquete={presencial}
          action={() => toCheckout(presencial.class_package_id)}
        />
      ));
    }
  };

  const renderEspeciales = () => {
    if (paquetes && paquetes !== null) {
      let paquetesRender = [...paquetes];
      paquetesRender = paquetesRender.filter(
        (paquete) => paquete.is_special_event
      );
      if (paquetesRender.length > 0) {
        return (
          <div id="especiales" className="container pb-5">
            <h2>Eventos Especiales</h2>
            <div className="row">
              {paquetesRender.map((presencial) => (
                <PaqueteCard
                  size="sm"
                  paquete={presencial}
                  is_special_event={true}
                  key={presencial.class_package_id}
                  action={() => toCheckout(presencial.class_package_id)}
                />
              ))}
            </div>
          </div>
        );
      }
    }
  };

  const renderCoaches = () => {
    if (coaches && coaches !== null) {
      return coaches
        .filter((coach) => coach.file_id !== null)
        .map((coach) => (
          <HomeCoachCard key={coach.instructor_id} coach={coach} />
        ));
    }
  };

  return (
    <div className="bg-dark">
      <StudioNavbar />
      <div id="video" className="row video-row">
        <StudioSlideshow />
      </div>
      <div
        id="trailer"
        className="row align-items-center video-background mt-5"
      >
        <div className="container py-5">
          <video
            className="video mw-75 w-75 d-block mx-auto my-3"
            poster={`${S3_ENDPOINT}/trailer-06-min.jpg`}
            controls
          >
            <source src={`${S3_ENDPOINT}/Online_Intro.mp4`}></source>
          </video>
        </div>
      </div>
      <div id="calendario" className="container-fluid  px-4 py-5">
        <Schedule />
      </div>
      {/* Paquetes */}
      <div id="paquetes" className="container pb-5">
        <h2>Paquetes de Clases</h2>
        <div className="row">{renderPresenciales()}</div>
      </div>
      {renderEspeciales()}
      {/* Ubicaciones */}
      <LocationsHome />
      <div className="container-fluid">
        <div className="container">
          <div className="row">{renderCoaches()}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudioLanding;
