import React, { useContext } from "react";
import { Router } from "@reach/router";
import { AuthContext } from "../context/AuthContext";
import StudioPanelNavbar from "../components/global/StudioPanelNavbar";
import MisReservaciones from "./studio/MisReservaciones";
import EditInformacion from "./EditInformacion";
import MiInformacion from "./MiInformacion";
import MisMetodos from "./MisMetodos";
import Pagos from "./Pagos";
import Shop from "./Shop";
import Presenciales from "./studio/Presenciales";
import Footer from "../components/global/Footer";
import StudioMobileMenu from "../components/common/StudioMobileMenu";
import MisCreditos from "./MisCreditos";

const StudioPanel = () => {
  const { user, signOut } = useContext(AuthContext);

  return (
    <div className="container-fluid px-0">
      <StudioMobileMenu signOut={signOut} />
      <StudioPanelNavbar user={user} signOut={signOut} />
      <div className="main-panel px-4">
        <div className="content overflow-x-hidden pt-5">
          <Router>
            <Presenciales path="/*" />
            <Shop path="/shop" />
            <MisCreditos path="/creditos" />
            <MiInformacion path="/informacion" />
            <EditInformacion path="/informacion/edit" />
            <MisMetodos path="/metodos-pago" />
            <MisReservaciones path="/reservaciones" />
            <Pagos path="/pagos" />
          </Router>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudioPanel;
