import { Link, navigate } from "@reach/router";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const SignUp = () => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [telefono, setTelefono] = useState("");
  const { user, spinner, signUp } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      return alert("Las contraseñas no coinciden");
    }
    if (!nombre || nombre === "") return alert("Ingresa tu nombre");
    if (!email || email === "") return alert("Ingresa tu correo");
    if (!telefono || telefono === "") return alert("Ingresa tu telefono");
    signUp(nombre, email, password, telefono);
  };

  useEffect(() => {
    if (user !== null) {
      navigate(
        `${
          window.location.href.includes("athome") ? "/athome" : "/studio"
        }/mylatina`
      );
    }
  }, [user]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-12 col-md-6 bg-black vh-100 px-0 hide-mobile"
          style={{
            backgroundImage:
              "url('https://latinatribe.s3.us-west-1.amazonaws.com/bg-login.png')",
            backgroundSize: "cover",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="col-12 col-md-6 vh-100">
          <div className="row align-items-center vh-100">
            <div className="container-fluid">
              <h1 className="text-center my-4">Join the community!</h1>
              <div
                id="login-card"
                className="card no-scale text-left shadow p-4"
              >
                <form onSubmit={handleSubmit}>
                  <label>Nombre</label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                  />
                  <label>Correo Electrónico</label>
                  <input
                    type="email"
                    className="form-control mb-3"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label>Contraseña</label>
                  <input
                    type="password"
                    className="form-control mb-3"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label>Confirmar Contraseña</label>
                  <input
                    type="password"
                    className="form-control mb-3"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                  />
                  <label>Teléfono (WhatsApp)</label>
                  <input
                    type="tel"
                    className="form-control mb-3"
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary"
                    value="Entrar"
                  >
                    {spinner ? (
                      <div className="spinner-border"></div>
                    ) : (
                      "Regístrate"
                    )}
                  </button>
                </form>
                <div className="container-fluid px-0 mt-4">
                  ¿Ya tienes cuenta?{" "}
                  <Link
                    to={
                      window.location.pathname.includes("athome")
                        ? "/athome/login"
                        : "/studio/login"
                    }
                  >
                    Inicia Sesión
                  </Link>
                </div>
              </div>
              <p className="mt-4 mw-500 d-block m-auto">
                ¿Necesitas recuperar tu cuenta?{" "}
                <Link
                  to={
                    (window.location.href.includes("athome")
                      ? "/athome"
                      : "/studio") + "/recuperar"
                  }
                >
                  Haz click aquí
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
