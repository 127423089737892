import React from "react";
import moment from "moment";
import ScheduleClass from "./ScheduleClass";

const ScheduleDay = ({ day, city, clases, location }) => {
  const renderSingleClasses = () => {
    if (Array.isArray(clases)) {
      if (location && location !== null) {
        clases = clases.filter(
          (clase) => parseInt(clase.location_id) === parseInt(location)
        );
      }
      if (city && city !== null) {
        clases = clases.filter(
          (clase) => clase.location !== null && clase.location.city === city
        );
      }
      if (clases.length === 0) {
        return (
          <p className="show-mobile">No hay más clases programadas este día.</p>
        );
      }
      clases = clases.sort((a, b) => (a.class_date > b.class_date ? 1 : -1));
      return clases.map((clase) => (
        <ScheduleClass
          key={clase.single_class_id}
          city={city}
          singleClass={clase}
        />
      ));
    }
  };

  const renderDay = () => {
    const day_string = moment(day.date).utc().format("dd");
    switch (day_string) {
      case "Tu":
        return "MARTES";
      case "We":
        return "MIÉRCOLES";
      case "Th":
        return "JUEVES";
      case "Fr":
        return "VIERNES";
      case "Sa":
        return "SÁBADO";
      case "Su":
        return "DOMINGO";
      default:
        return "LUNES";
    }
  };

  return (
    <div className="schedule-col px-0 text-center">
      <div>
        <div className="row mx-0 pt-2">
          <div className="container-fluid text-center text-white">
            {moment(day.date).utc().format("DD")}
          </div>
        </div>
        <div className="row mx-0 my-3 bg-primary text-dark">
          <div className="container-fluid text-center bold">{renderDay()}</div>
        </div>
      </div>
      <div className="px-2">{renderSingleClasses()}</div>
    </div>
  );
};

export default ScheduleDay;
