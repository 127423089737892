import React from "react";
import { Link } from "@reach/router";
import latina_logo from "../../assets/images/Latina_Icono2.png";
import { S3_ENDPOINT } from "../../utils";

const StudioPanelNavbar = ({ user, signOut }) => {
  return (
    <nav className="navbar navbar-expand-lg bg-dark hide-mobile">
      <div className="container-fluid">
        <Link to="/studio" className="navbar-brand">
          <img src={latina_logo} className="logo-navbar" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <Link to="/studio/mylatina" className="nav-link">
                Calendario
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/studio/mylatina/shop" className="nav-link">
                Paquetes
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {user.file !== null ? (
                  <img
                    src={`${S3_ENDPOINT}/${user.file.name}.${user.file.type}`}
                    className="user-thumbnail"
                  />
                ) : (
                  <i className="ms-2 fa fa-user-circle"></i>
                )}{" "}
                {user.name}
              </a>
              <ul
                className="dropdown-menu user-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link
                    to="/studio/mylatina/informacion"
                    className="dropdown-item"
                  >
                    Mi Información
                  </Link>
                </li>
                <li>
                  <Link to="/studio/mylatina/pagos" className="dropdown-item">
                    Mis Compras
                  </Link>
                </li>
                <li>
                  <Link
                    to="/studio/mylatina/metodos-pago"
                    className="dropdown-item"
                  >
                    Mis Métodos de Pago
                  </Link>
                </li>
                <li>
                  <Link
                    to="/studio/mylatina/reservaciones"
                    className="dropdown-item"
                  >
                    Mis Reservaciones
                  </Link>
                </li>
                <li className="mt-3">
                  <button
                    className="dropdown-item text-danger"
                    onClick={signOut}
                  >
                    Salir <i className="ms-2 fa fa-sign-out-alt"></i>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default StudioPanelNavbar;
