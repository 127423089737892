import React, { useState, useContext, useEffect } from "react";
import { LocationsContext } from "../../context/LocationsContext";

const ScheduleLocationPicker = ({
  modifier,
  cityModifier,
  selectedCity,
  selectedLocation,
}) => {
  const [cities, setCities] = useState(null);
  const { locations, getLocations } = useContext(LocationsContext);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (Array.isArray(locations)) {
      handleCities();
    }
  }, [locations]);

  const handleCities = () => {
    const citySet = new Set();
    locations.forEach(({ city }) => {
      if (city && city !== null && city !== "") {
        citySet.add(city);
      }
    });
    setCities(Array.from(citySet));
  };

  const renderCities = () => {
    if (Array.isArray(cities)) {
      return [
        <option key="all" value="">
          Todos las Ciudades
        </option>,
        ...cities.map((city) => (
          <option key={city} value={city}>
            {city}
          </option>
        )),
      ];
    }
  };

  const renderLocations = () => {
    if (Array.isArray(locations)) {
      let locationsRender = [...locations];
      if (selectedCity && selectedCity !== null && selectedCity !== "") {
        locationsRender = locationsRender.filter(
          ({ city }) => city === selectedCity
        );
      }
      return [
        <option key="all" value="">
          Todos los Estudios
        </option>,
        ...locationsRender.map((location) => (
          <option key={location.location_id} value={location.location_id}>
            {location.name}
          </option>
        )),
      ];
    }
  };

  return (
    <div className="row ms-0 my-3">
      <div className="container-fluid px-0">
        <label>Buscar por Ciudad</label>
        <select
          value={selectedCity}
          className="form-control mb-3"
          onChange={(e) => cityModifier(e.target.value)}
        >
          {renderCities()}
        </select>
        <label>Buscar por Estudio</label>
        <select
          value={selectedLocation}
          className="form-control mb-3"
          onChange={(e) => modifier(e.target.value)}
        >
          {renderLocations()}
        </select>
      </div>
    </div>
  );
};

export default ScheduleLocationPicker;
