import React from "react";
import moment from "moment";
import FilaReservaciones from "./FilaReservaciones";

const ReservationsTable = ({ filtered, class_reservations }) => {
  const renderReservations = () => {
    if (Array.isArray(class_reservations)) {
      if (class_reservations.length === 0) {
        return (
          <div className="container-fluid px-0">
            <p>Aún no has reservado clases.</p>
            <button className="btn btn-primary">Ir a Reservar</button>
          </div>
        );
      }
      let reservationsRender = [...class_reservations];
      if (filtered) {
        reservationsRender = reservationsRender.filter((reservation) => {
          if (reservation.single_class && reservation.single_class !== null) {
            return moment(reservation.single_class.class_date)
              .utc()
              .isAfter(moment().utc().subtract(1, "days"));
          }
          return false;
        });
      }
      return (
        <div className="table-responsive">
          <table className="table text-white">
            <thead>
              <tr>
                <td>Ubicación</td>
                <td>Coach</td>
                <td>Fecha</td>
                <td>Lugar</td>
                <td>Acciones</td>
              </tr>
            </thead>
            <tbody>
              {reservationsRender.map((reservation) => (
                <FilaReservaciones
                  key={reservation.class_reservation_id}
                  reservacion={reservation}
                />
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };
  return <div>{renderReservations()}</div>;
};
export default ReservationsTable;
