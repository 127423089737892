import { Link } from "@reach/router";
import React from "react";
import { S3_ENDPOINT } from "../../utils";

const HomeCoachCard = ({ coach }) => {
  const { name, last_name, file_name, file_type } = coach;

  return (
    <Link
      to={`/coach/${coach.instructor_id}`}
      className="col-12 col-md-4 col-lg-3 p-4 no-decoration text-white"
    >
      <div className="card no-scale shadow-sm px-0 py-0 text-center position-relative">
        <img
          src={`${S3_ENDPOINT}/${file_name}.${file_type}`}
          className="mw-100 w-100 coach-home-img"
        />
        <div className="pt-3">
          <h4>
            {name} {last_name}
          </h4>
        </div>
      </div>
    </Link>
  );
};

export default HomeCoachCard;
