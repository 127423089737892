import React, { useContext, useEffect, useState } from "react";
import { Link } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";
import { ReservationsContext } from "../../context/ReservationsContext";
import { ClassInstructorContext } from "../../context/ClassInstructorContext";
import ReservationsTable from "../../components/reservaciones/ReservationsTable";

const MisReservaciones = () => {
  const { user } = useContext(AuthContext);
  const { setClase } = useContext(ClassInstructorContext);
  const { reservations, getMyReservations } = useContext(ReservationsContext);

  const [filtered, setFiltered] = useState(true);

  useEffect(() => {
    setClase(null);
    getMyReservations();
  }, []);

  const renderReservaciones = () => {
    if (Array.isArray(reservations)) {
      return (
        <ReservationsTable
          filtered={filtered}
          class_reservations={reservations}
        />
      );
    }
    return <div className="spinner-border"></div>;
  };
  return (
    <div className="container-fluid px-0">
      <h1>Mis Reservaciones</h1>
      <p>Sólo puedes cancelar clases con 12 horas de anticipación</p>
      <div className="row border-bottom pb-3 mb-3">
        <div className="col-6">
          <button
            type="button"
            className="btn btn-outline-light me-3"
            onClick={() => setFiltered(!filtered)}
          >
            {filtered ? "Anteriores" : "Próximas"}
          </button>
        </div>
        <div className="col-6 text-end">
          <Link className="h1 text-white" to={"/studio/mylatina/creditos"}>
            Créditos: {user.available_classes.length}
          </Link>
        </div>
      </div>
      {renderReservaciones()}
    </div>
  );
};

export default MisReservaciones;
