import React, { useState } from "react";
import icono_latina from "../../assets/images/Latina_Icono2.png";
import { Link } from "@reach/router";
import MobileMenuItem from "./MobileMenuItem";
import { cuenta } from "../../utils";

const AtHomeMobileMenu = ({ signOut }) => {
  const [display, setDisplay] = useState(false);

  return (
    <>
      <nav className="panel-mobile-menu navbar navbar-expand-lg navbar-dark bg-dark row py-2">
        <div className="container-fluid pl-0">
          <Link
            className="navbar-brand"
            to={window.location.href.includes("athome") ? "/athome" : "/studio"}
          >
            <img src={icono_latina} className="logo-navbar" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setDisplay(!display)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
      <div
        className="bg-dark w-100 vh-100 drawer-menu"
        style={{
          position: "absolute",
          left: display ? 0 : "-100vw",
        }}
      >
        <ul className="navbar-nav pt-5 px-3 mt-5">
          <h3 className="ps-2 mt-4 text-white border-bottom pb-3">
            Mis Clases
          </h3>
          <MobileMenuItem
            name="Videos"
            handle="/athome/mylatina"
            setDisplay={setDisplay}
          />
          <MobileMenuItem
            name="Get Access"
            handle="/checkout/15"
            setDisplay={setDisplay}
          />
          <h3 className="ps-2 mt-4 text-white border-bottom pb-3">Mi Cuenta</h3>
          {cuenta.map(({ name, handle }) => (
            <MobileMenuItem
              key={name}
              name={name}
              handle={handle}
              setDisplay={setDisplay}
            />
          ))}
          <li className="nav-item mobile mt-5">
            <button className="btn btn-link nav-link" onClick={signOut}>
              <i className="fa fa-sign-out-alt fa-flip-horizontal ps-2"></i>{" "}
              Salir
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AtHomeMobileMenu;
