import { Link, navigate } from "@reach/router";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, spinner, signIn } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(email, password);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (user !== null) {
      if (window.location.href.includes("athome")) {
        navigate("/athome/mylatina");
      }
      if (window.location.href.includes("studio")) {
        navigate("/studio/mylatina");
      }
    }
  }, [user]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-12 col-md-6 bg-black vh-100 px-0 hide-mobile"
          style={{
            backgroundImage:
              "url('https://latinatribe.s3.us-west-1.amazonaws.com/bg-login.png')",
            backgroundSize: "cover",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="col-12 col-md-6 vh-100">
          <div className="row align-items-center vh-100">
            <div className="container-fluid">
              <h1 className="text-center my-4">Welcome Back!</h1>
              <div
                id="login-card"
                className="card no-scale text-left shadow p-4"
              >
                <form onSubmit={handleSubmit}>
                  <label>Correo Electrónico</label>
                  <input
                    type="email"
                    className="form-control mb-3"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label>
                    Contraseña{" "}
                    <button
                      type="button"
                      className="btn btn-link text-muted text-left text-small py-0 mb-1"
                      onClick={(e) => {
                        e.preventDefault();
                        togglePassword();
                      }}
                    >
                      <span className="text-montserrat text-small text-auto">
                        {showPassword ? "Ocultar" : "Mostrar"}
                      </span>
                    </button>
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control mb-3"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button type="submit" className="btn btn-primary">
                    {spinner ? (
                      <div className="spinner-border"></div>
                    ) : (
                      "Log In"
                    )}
                  </button>
                </form>
                <div className="container-fluid px-0 mt-4">
                  ¿Aun no tienes cuenta?{" "}
                  <Link
                    to={
                      (window.location.href.includes("athome")
                        ? "/athome"
                        : "/studio") + "/signup"
                    }
                    className="text-primary"
                  >
                    Crea tu cuenta
                  </Link>
                </div>
                <div className="container-fluid px-0 mt-4">
                  <Link
                    to={
                      (window.location.href.includes("athome")
                        ? "/athome"
                        : "/studio") + "/recuperar"
                    }
                    className="text-muted"
                  >
                    ¿Olvidaste tu contraseña?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
