import React, { useContext, useEffect } from "react";
import SingleClass from "../schedule/SingleClass";
import { getLocalMomentDiff, hideModal } from "../../utils";
import { ReservationsContext } from "../../context/ReservationsContext";
import { ClassInstructorContext } from "../../context/ClassInstructorContext";
import { ModalContext } from "../../context/ModalContext";
import CancelReservation from "./CancelReservation";
import moment from "moment";

const FilaReservaciones = ({ reservacion }) => {
  const { clase, getClase, setClase } = useContext(ClassInstructorContext);
  const { reservations, reservation, setReservation } =
    useContext(ReservationsContext);
  const { modalComponent } = useContext(ModalContext);

  const { cancelReservacion, updateReservacion } =
    useContext(ReservationsContext);

  useEffect(() => {
    return () => {
      setClase(null);
    };
  }, []);

  useEffect(() => {
    if (clase !== null) {
      if (
        clase.single_class_id === reservacion.single_class_id &&
        reservacion.class_reservation_id === reservation.class_reservation_id
      ) {
        addPlace(clase);
      }
    }
  }, [clase]);

  const addPlace = (clase) => {
    modalComponent(
      "Reservar Lugar",
      <SingleClass
        clase={clase}
        postReservacion={(clase) =>
          updateReservacion({
            ...clase,
            class_reservation_id: reservacion.class_reservation_id,
          })
        }
        hideModal={hideModal}
      />
    );
  };

  const confirmCancel = (reservation) => {
    modalComponent(
      "Cancelar Clase",
      <CancelReservation
        reservations={reservations}
        singleClass={reservation.single_class}
        cancelReservacion={cancelReservacion}
      />
    );
  };

  const renderInstructors = (singleClass) => {
    if (singleClass.class_instructors.length === 1) {
      const instructor = singleClass.class_instructors[0].instructor;
      if (instructor !== null) {
        return instructor.nick_name !== null
          ? instructor.nick_name
          : instructor.name;
      }
      return "N/D";
    }
    return singleClass.class_instructors.map((class_instructor) => (
      <span className="d-block">
        {class_instructor.instructor !== null
          ? class_instructor.instructor.nick_name !== null
            ? class_instructor.instructor.nick_name
            : class_instructor.instructor.name
          : "N/D"}
      </span>
    ));
  };

  const renderActions = () => {
    if (reservacion.deletedAt !== null) {
      return <span className="badge bg-danger">Cancelada</span>;
    }
    if (reservacion.attend) {
      return <span className="badge badge-pill bg-primary">Exitosa</span>;
    }
    if (reservacion.single_class && reservacion.single_class !== null) {
      const diff =
        moment
          .utc(reservacion.single_class.class_date)
          .diff(moment(), "hours") + 6;
      if (diff >= 12 && reservacion.single_class.class_package_id === null) {
        return (
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => confirmCancel(reservacion)}
          >
            <i className="fa fa-times"></i>
          </button>
        );
      }
      const diff_to_class = getLocalMomentDiff(
        reservacion.single_class.class_date,
        "minutes"
      );
      if (diff_to_class > 15) {
        return (
          <span className="badge badge-pill bg-warning text-dark">
            No asistí
          </span>
        );
      }
    }
  };

  const renderLocation = (single_class) => {
    if (single_class.location && single_class.location !== null) {
      return single_class.location.name;
    }
  };

  const renderDate = (single_class) => {
    if (single_class && single_class !== null) {
      return moment(reservacion.single_class.class_date)
        .utc()
        .format("ddd DD MMM, HH:mm");
    }
  };

  const renderSpot = (reservation) => {
    if (reservation.spot !== null) {
      return reservation.spot;
    }
    if (
      moment().isBefore(moment(reservation.single_class.class_date)) &&
      reservation.deletedAt === null
    ) {
      return (
        <button
          className="btn btn-outline-light"
          onClick={() => {
            setReservation(reservation);
            getClase(reservation.single_class_id);
          }}
        >
          <i className="fas fa-map-pin"></i>
        </button>
      );
    }
  };

  return (
    <tr key={reservacion.class_reservation_id}>
      <td>{renderLocation(reservacion.single_class)}</td>
      <td>{renderInstructors(reservacion.single_class)}</td>
      <td>{renderDate(reservacion.single_class)}</td>
      <td>{renderSpot(reservacion)}</td>
      <td>{renderActions()}</td>
    </tr>
  );
};

export default FilaReservaciones;
