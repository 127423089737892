import React, { useContext, useEffect } from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../utils";
import { AuthContext } from "../context/AuthContext";
import { S3_ENDPOINT } from "../utils";
import { PurchasesContext } from "../context/PurchasesContext";
import { navigate } from "@reach/router";
import StatusBadge from "../components/common/StatusBadge";
import moment from "moment";

const ThankYou = ({ payment_id }) => {
  const { user } = useContext(AuthContext);
  const { purchase, getPurchase } = useContext(PurchasesContext);

  useEffect(() => {
    if (user !== null) {
        setInterval(fetchPurchase, 1000);
    }
  }, [user]);

  const fetchPurchase = () => {
    getPurchase(payment_id);
  }

  useEffect(() => {
    if (purchase && purchase !== null) {
      if (
        purchase.class_package_id === 11788 &&
        purchase.status === "completed"
      ) {
        navigate("/bichota");
      }
    }
  }, [purchase]);

  const isOnline = () => {
    if (purchase.class_package !== null) {
      return purchase.class_package.include_online;
    }
  };

  const renderorden = () => {
    if (purchase && purchase !== null) {
      return (
        <div
          style={{ maxWidth: 500, margin: "auto" }}
          className="card no-scale my-3 w-100"
        >
          <h1 className="h2">Resumen</h1>
          <h4>Compra #{purchase.purchase_id}</h4>
          <p>Fecha: {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}</p>
          <p>Total: ${formatMonto(purchase.total_payment)} MXN</p>
          <p>
            Estado: <StatusBadge status={purchase.status} />
          </p>
          <p>
            *Si el estado sigue pendiente después de 1 minuto, quiere decir que
            hubo un error con tu pago.
          </p>
          <p>
            Expira en:{" "}
            {moment(purchase.expiration_date).format("DD MMM YYYY HH:mm")}
          </p>
          <Link
            to={isOnline() ? "/athome/mylatina" : "/studio/mylatina"}
            className="btn btn-primary"
          >
            Ir a Mi Panel
          </Link>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderImage = () => {
    if (purchase && purchase !== null) {
      if (purchase.status === "completed") {
        if (purchase.class_package !== null) {
          if (purchase.class_package.is_special_event) {
            return (
              <img
                src={`${S3_ENDPOINT}/recibo-masterclass.png`}
                className="mw-100 w-100 d-block m-auto"
              />
            );
          }
          if (purchase.class_package_id === 11758) {
            return (
              <img
                src={`${S3_ENDPOINT}/recibo-kylie.jpeg`}
                className="mw-100 w-100 d-block m-auto"
              />
            );
          }
          if (purchase.class_package.include_online) {
            return (
              <img
                src={`${S3_ENDPOINT}/recibo-online.png`}
                className="mw-100 w-100 d-block m-auto"
              />
            );
          } else {
            return (
              <img
                src={`${S3_ENDPOINT}/recibo-presenciales.png`}
                className="mw-100 w-100 d-block m-auto"
              />
            );
          }
        }
      }
    }
  };

  return (
    <div className="container-fluid bg-dark text-white vh-100 py-5">
      <div className="container">{renderImage()}</div>
      <div className="container">{renderorden()}</div>
    </div>
  );
};

export default ThankYou;
