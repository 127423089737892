import { Link } from "@reach/router";
import React from "react";
import logo_latina from "../../assets/images/Latina_Icono2.png";

const StudioNavbar = ({ isBannerActive }) => {
  return (
    <nav
      id="navbar"
      className="navbar navbar-expand-lg p-2"
      style={{ top: isBannerActive ? "30px" : 0 }}
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={logo_latina} className="logo-navbar" />
        </a>
        <div className="col col-md-4 col-xl-2 show-mobile text-right">
          <Link to="/studio/login" className="btn btn-login position-relative">
            <i className="fa fa-heart text-primary"></i>
            <span className="d-block position-relative">Log In</span>
          </Link>
        </div>
        {/*<button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>*/}
        <div className="collapse navbar-collapse mw-100" id="navbarNav">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col col-md-8 col-xl-10">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" href="#calendario">
                      Horarios
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#paquetes">
                      Paquetes
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col col-md-4 col-xl-2 text-right">
                <Link
                  to="/studio/login"
                  className="btn btn-login position-relative"
                >
                  <i className="fa fa-heart text-primary"></i>
                  <span className="d-block position-relative">Log In</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default StudioNavbar;
