import { Link } from "@reach/router";
import React from "react";

const CompleteInformation = () => {
  return (
    <div className="container-fluid px-0">
      <p>
        Para darte el mejor servicio, necesitamos que completes todos tus datos.
      </p>
      <p className="bold">
        Esto es en caso de que tengamos que informarte de algo antes de tu clase
        podamos contactarte bebé.
      </p>
      <p>
        Tienes que ingresar correctamente: Nombre, Apellidos y Teléfono de al
        menos 10 dígitos.
      </p>
      <Link to="/studio/mylatina/informacion/edit" className="btn btn-primary">
        Completar Información
      </Link>
    </div>
  );
};

export default CompleteInformation;
