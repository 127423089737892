export const isReserved = (single_class_id, class_reservations) => {
  return getClassReservation(single_class_id, class_reservations) !== undefined;
};

export const getClassReservation = (single_class_id, class_reservations) => {
  if (Array.isArray(class_reservations)) {
    let hasReservation = class_reservations.find(
      (class_reservation) =>
        class_reservation.single_class_id === single_class_id &&
        class_reservation.deletedAt === null
    );
    return hasReservation;
  }
};

export const getTakenSpots = (class_reservations) => {
  if (Array.isArray(class_reservations)) {
    return class_reservations.map(({ spot }) => spot);
  }
};

export const getMapRows = (single_class) => {
  const { class_type } = single_class;
  if (class_type && class_type !== null) {
    const { spot_map } = class_type;
    if (spot_map && spot_map !== null) {
      return spot_map.split(",").map((num) => parseInt(num));
    }
  }
};
