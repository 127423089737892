import React, { useContext, useState, useEffect } from "react";
import {
  userInformatinCompleted,
  getClassAvailableSpaces,
  getLocalMomentDiff,
  hideModal,
  getValue,
} from "../../utils";
import { RESERVATION_TRESHOLD, RESERVATION_UNIT } from "../../constants";
import { ReservationsContext } from "../../context/ReservationsContext";
import { getClassInstructorsNames } from "../../utils/instructors";
import CompleteInformation from "../global/CompleteInformation";
import { getClassReservation } from "../../utils/single_class";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import ExpiredCredits from "../global/ExpiredCredits";
import { navigate } from "@reach/router";
import CancelClass from "./CancelClass";
import SingleClass from "./SingleClass";
import moment from "moment";
import RestrictedCredits from "../global/RestrictedCredits";
import Waitlist from "./Waitlist";
import { AppConfigContext } from "../../context/AppConfigContext";

const ScheduleClass = ({ singleClass, isHome }) => {
  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);
  const appconfig = useContext(AppConfigContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { disabled, reservations, postReservacion, cancelReservacion } =
    useContext(ReservationsContext);

  const { class_package_id } = singleClass;

  useEffect(() => {
    if (!disabled && loading) {
      setLoading(false);
    }
  }, [disabled]);

  const handleReservation = (reservation) => {
    hideModal();
    clearModal();
    setLoading(true);
    postReservacion(reservation);
  };

  const handleCancelReservation = () => {
    clearModal();
    setTimeout(() => {
      modalComponent(
        "Cancelar Clase",
        <CancelClass
          singleClass={singleClass}
          reservations={reservations}
          cancelReservacion={cancelReservacion}
        />
      );
    }, 500);
  };

  const showClassModal = () => {
    if (user === null) return navigate("/studio/login");
    if (!disabled) {
      if (class_package_id && class_package_id !== null) {
        return navigate(`/checkout/${class_package_id}`);
      }
      if (isHome) {
        return navigate("/studio/login");
      }
      let reservation = getClassReservation(
        singleClass.single_class_id,
        reservations
      );
      if (reservation && reservation !== null) {
        return modalComponent(
          "Detalles de Clase",
          <SingleClass
            clase={singleClass}
            hideModal={hideModal}
            reservations={reservations}
            postReservacion={handleReservation}
            cancelReservacion={handleCancelReservation}
          />
        );
      }
      let notFull = getClassAvailableSpaces(singleClass) > 0;
      let hasCredits = user.available_classes.length > 0;
      let validUserInfo = userInformatinCompleted(user);
      let diff = getLocalMomentDiff(singleClass.class_date, RESERVATION_UNIT);
      if (diff <= RESERVATION_TRESHOLD) {
        if (notFull) {
          if (hasCredits) {
            if (!validUserInfo) {
              return modalComponent(
                "Completa tu información",
                <CompleteInformation />
              );
            }
            if (singleClass.class_type && singleClass.class_type !== null) {
              if (getValue(singleClass.class_type, "restricted", "boolean")) {
                const availableClasses = user.available_classes.filter(
                  ({ class_type_id }) =>
                    String(class_type_id) === String(singleClass.class_type_id)
                );
                if (availableClasses.length === 0) {
                  return modalComponent(
                    "Necesitas Créditos",
                    <RestrictedCredits
                      class_type_name={singleClass.class_type.name}
                    />
                  );
                }
              } else {
                const generalClasses = user.available_classes.filter(
                  ({ class_type_id }) => class_type_id === null
                );
                if (generalClasses.length === 0) {
                  return modalComponent(
                    "Necesitas Créditos",
                    <RestrictedCredits
                      class_type_name={singleClass.class_type.name}
                    />
                  );
                }
              }
            }
            modalComponent(
              "Reservar Clase",
              <SingleClass
                clase={singleClass}
                hideModal={hideModal}
                reservations={reservations}
                postReservacion={handleReservation}
                cancelReservacion={handleCancelReservation}
              />
            );
          } else {
            modalComponent("Créditos Agotados", <ExpiredCredits />);
          }
        } else if (getValue(appconfig, "whatsapp_connected", "boolean")) {
          modalComponent(
            "Lista de Espera",
            <Waitlist
              handleCancel={clearModal}
              single_class_id={singleClass.single_class_id}
            />
          );
        }
      }
    }
  };

  const renderSpots = () => {
    if (loading) {
      return <div className="spinner-border text-white"></div>;
    }
    let reservation = getClassReservation(
      singleClass.single_class_id,
      reservations
    );
    if (reservation) {
      return (
        <div className="container-fluid text-center px-1">
          <span className="text-primary">
            <i className="fa fa-heart"></i> {reservation.spot}
          </span>
        </div>
      );
    }
    let diff = getLocalMomentDiff(singleClass.class_date, RESERVATION_TRESHOLD);
    if (diff <= RESERVATION_TRESHOLD) {
      const available_space = getClassAvailableSpaces(singleClass);
      if (available_space <= 0) {
        return <span className="badge badge-pill bg-danger">Sold Out</span>;
      }
      if (available_space <= 1) {
        return (
          <div>
            <span className="badge badge-pill bg-warning text-dark text-capitalize">
              {available_space} spot left
            </span>
          </div>
        );
      }
      return (
        <div>
          <span className="badge badge-pill bg-verde text-dark text-capitalize">
            Available
          </span>
        </div>
      );
    }
  };

  const renderInstructors = () => {
    return getClassInstructorsNames(singleClass.class_instructors);
  };

  const renderClassType = () => {
    if (parseInt(singleClass.class_type_id) > 3000) {
      const { class_type } = singleClass;
      if (class_type && class_type !== null) {
        return (
          <span
            className="badge badge-pill text-dark text-capitalize"
            style={{ backgroundColor: class_type.color }}
          >
            {class_type.name}
          </span>
        );
      }
    }
  };

  const renderLocation = () => {
    if (singleClass.location !== null) {
      return singleClass.location.name;
    }
  };

  const renderDescription = () => {
    if (singleClass.description && singleClass.description !== null) {
      return <p className="mb-1">{singleClass.description}</p>;
    }
  };

  return (
    <div
      className={`schedule-class text-white p-2 my-2 bg-gray ${
        class_package_id !== null ? "border-primary" : ""
      }`}
      onClick={showClassModal}
    >
      <p className="font-weight-bold mb-1">
        <i className="far fa-clock"></i>{" "}
        {moment(singleClass.class_date).utc().format("HH:mm")}
      </p>
      {renderDescription()}
      <p className="mb-0">{renderInstructors()}</p>
      <p className="mb-1 small bold">{renderLocation()}</p>
      {renderSpots()}
      {renderClassType()}
    </div>
  );
};

export default ScheduleClass;
