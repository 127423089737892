import React, { useContext, useEffect } from "react";
import { Router } from "@reach/router";
import Login from "./views/Login";
import { AuthContext } from "./context/AuthContext";
import Modal from "./components/global/Modal";
import ErrorAlert from "./components/common/ErrorAlert";
import SuccessAlert from "./components/common/SuccessAlert";
import Checkout from "./views/Checkout";
import ThankYou from "./views/ThankYou";
import SignUp from "./views/SignUp";
import Recuperar from "./views/Recuperar";
import Loading from "./views/Loading";
import StudioPanel from "./views/StudioPanel";
import StudioLanding from "./views/studio/StudioLanding";

const Studio = () => {
  const { user, userLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    userLoggedIn();
    document.title = "Latina Studio";
    document.documentElement.style.setProperty(`--primary`, "#cac7e0");
  }, []);

  const hasUser = () => {
    return user !== null;
  };

  return (
    <>
      <Router>
        <StudioLanding path="/*" default />
        <Login path="/login" />
        <SignUp path="/signup" />
        <Recuperar path="/recuperar" />
        <Checkout path="/checkout/:class_package_id/*" />
        <ThankYou path="/thankyou/:purchase_id/*" />
        {hasUser() ? (
          <StudioPanel path="/mylatina/*" />
        ) : (
          <Loading path="/mylatina/*" />
        )}
      </Router>
      <Modal />
      <ErrorAlert />
      <SuccessAlert />
    </>
  );
};

export default Studio;
